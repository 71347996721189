import React, { useState } from 'react';
import { Calendar, Download } from 'lucide-react';


// Define types for section data
interface QuestionAnswer {
  question: string;
  answer: string | string[];
}

const GestationalDiabetesPage: React.FC = () => {
  // Define sections based on PDF content
  const sections: QuestionAnswer[] = [
    {
      question: "What is Gestational Diabetes (GDM)?",
      answer: ["Gestational diabetes is a type of diabetes that can develop during pregnancy in individuals who don't already have diabetes."]
    },
    {
      question: "Why does GDM occur?",
      answer: [
        "Insulin is a hormone that enables glucose (sugar) in the bloodstream to enter the cells of the body. Sugar is the source of energy for cells.",
        "During pregnancy, the fetus and placenta produce hormones that make the pregnant individual resistant to their own insulin.",
        "Most pregnant individuals can produce enough extra insulin to compensate for this and thus keep their blood sugar level normal. However, some cannot, so their blood sugar level rises, resulting in gestational diabetes."
      ]
    },
    {
      question: "Who are at risk of developing GDM?",
      answer: [
        "Gestational Diabetes is more common in women who:",
        "- Are older than 25 years",
        "- Are overweight/ obese",
        "- Are physically inactive",
        "- Have had gestational diabetes before",
        "- Have had a very large baby (>4kg)",
        "- Have High Blood Pressure",
        "- Have a close relative with diabetes",
        "- Have Polycystic Ovarian Syndrome(PCOS)",
        "- Have had a stillbirth in a previous pregnancy"
      ]
    },
    {
      question: "How can GDM affect mother and baby?",
      answer: [
        "If gestational diabetes is not treated, it can increase the risk of certain problems for mother and baby:",
        "- Increases the risk of having a very large baby (a condition called macrosomia) and possible cesarean birth",
        "- High blood pressure and preeclampsia are more common in women with gestational diabetes",
        "- Babies born to mothers with gestational diabetes may have problems with breathing, low glucose levels, and jaundice"
      ]
    },
    {
      question: "When to test for Gestational Diabetes?",
      answer: [
        "All pregnant women should be screened for gestational diabetes.",
        "Timing of test — Testing for gestational diabetes is usually done once between 24 and 28 weeks of pregnancy. However, testing for diabetes may be done as early as your first prenatal visit if you have risk factors for diabetes.",
        "How to Test? – OGTT"
      ]
    },
    {
      question: "How to manage Gestational Diabetes?",
      answer: [
        "You will need more frequent prenatal care visits to monitor your health and your fetus's health. You will need to track your blood sugar and do things to keep it under control. Doing so will reduce the risks to both you and your fetus.",
        "For many women, a healthy diet and regular exercise will control blood sugar. Some women may need medications to help reach normal blood sugar levels even with diet changes and exercise."
      ]
    },
    {
      question: "How to keep track of Blood Sugar levels?",
      answer: [
        "You will use a glucose meter to test your blood sugar levels. This device measures blood sugar from a small drop of blood.",
        "Usually blood sugar should be checked 4 times/day:",
        "- Fasting glucose level—first thing in the morning, before you eat",
        "- 1 or 2 hours after breakfast",
        "- 1 or 2 hours after lunch",
        "- 1 or 2 hours after dinner",
        "Healthy Target Range of Blood Sugar:",
        "- Fasting glucose level: No higher than 95",
        "- 1 hr after eating: No higher than 140",
        "- 2 hr after eating: No higher than 120"
      ]
    },
    {
      question: "What dietary changes are required?",
      answer: [
        "The first treatment for gestational diabetes is eating right:",
        "- Should eat regular meals throughout the day. You may need to eat small snacks as well, especially at night. Eating regularly helps avoid dips and spikes in your blood sugar level. Often, three meals and two to three snacks per day are recommended.",
        "- Eat small amount of carbohydrates in each meal",
        "- Avoid sweet desserts and presweetened beverages",
        "- Include protein with limited saturated fat, such as trimmed red meat and pork, chicken, and fish. Other protein foods like cheese, eggs, nuts, seeds, and peanut butter, etc. should be included",
        "- Increase greens and salads in diet",
        "- A dietician should be consulted for diet plan"
      ]
    },
    {
      question: "Will regular exercise help control GDM?",
      answer: [
        "- Exercise helps keep blood sugar levels in the normal range",
        "- You and your ob-gyn can decide how much and what type of exercise is best for you",
        "- In general, 30 minutes of moderate-intensity aerobic exercise at least 5 days a week is recommended",
        "- Walking is a great exercise for all pregnant women",
        "- In addition to weekly aerobic exercise, a walk for 10 to 15 minutes after each meal can lead to better blood sugar control"
      ]
    },
    {
      question: "What medications are used to treat GDM?",
      answer: [
        "- If diet and exercise are not enough, medication may be needed to control blood glucose levels",
        "- Oral medication – Metformin",
        "- Insulin is the recommended medication during pregnancy to help women control their blood sugar. Insulin does not cross the placenta, so it doesn't affect the fetus"
      ]
    },
    {
      question: "Future health concerns for mother after delivery?",
      answer: [
        "- GDM greatly increases the risk of developing diabetes in next pregnancy",
        "- 15-70% of women with GDM develop Type 2 Diabetes later on in life",
        "- You should have a test for diabetes 6 –12 weeks after you give birth. If postpartum glucose test result is normal, you need to be tested for diabetes every 3 years"
      ]
    },
    {
      question: "Future health concerns for baby?",
      answer: [
        "- Children of women who had GD may be at risk of becoming overweight or obese during childhood. These children also have a higher risk of developing diabetes",
        "- Breastfeeding should be encouraged"
      ]
    }
  ];

  // State to manage expanded/collapsed sections
  const [expandedSections, setExpandedSections] = useState<number[]>([]);

  // Toggle section expansion
  const toggleSection = (index: number) => {
    setExpandedSections(prev => 
      prev.includes(index) 
        ? prev.filter(i => i !== index) 
        : [...prev, index]
    );
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = '/GESTATIONAL-DIABETES.pdf';
    link.download = 'gestational-diabetes.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl mt-28 md:mt-48">
      <h1 className="text-3xl font-bold text-center mb-8 text-blue-800">
        Gestational Diabetes Information
      </h1>
      
      <div className="space-y-4">
        {sections.map((section, index) => (
          <div 
            key={index} 
            className="border border-gray-200 rounded-lg shadow-sm"
          >
            <button 
              onClick={() => toggleSection(index)}
              className="w-full text-left p-4 bg-gray-100 hover:bg-gray-200 transition-colors flex justify-between items-center"
            >
              <h2 className="font-semibold text-lg text-blue-700">
                {section.question}
              </h2>
              <span className="text-xl">
                {expandedSections.includes(index) ? '−' : '+'}
              </span>
            </button>
            
            {expandedSections.includes(index) && (
              <div className="p-4 bg-white">
                {typeof section.answer === 'string' ? (
                  <p>{section.answer}</p>
                ) : (
                  section.answer.map((ans, i) => (
                    <p key={i} className={`mb-2 ${ans.startsWith('-') ? 'pl-4' : ''}`}>
                      {ans}
                    </p>
                  ))
                )}
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="mt-8 space-y-4 sm:space-y-0 sm:space-x-4 flex flex-col sm:flex-row justify-center items-center">
        <button 
          onClick={handleDownload}
          className="flex items-center justify-center px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors w-full sm:w-auto"
        >
          <Download className="mr-2" size={20} />
          Download Patient Guide
        </button>
        <button 
          onClick={()=>  window.location.href = "/contact"}
          className="flex items-center justify-center px-6 py-3 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors w-full sm:w-auto"
        >
          <Calendar className="mr-2" size={20} />
          Book Appointment
        </button>
      </div>

      <div className="mt-6 sm:mt-8 text-center">
        <p className="text-xs sm:text-sm text-gray-600 italic">
          Consult Dr. Swati Kanodia for personalized medical advice.
        </p>
        <div className="mt-4 text-sm text-gray-700">
          <p>Contact: +91 9015550000</p>
          <p>Email: dr.swatikanodia@gmail.com</p>
        </div>
      </div>
    </div>
  );
};

export default GestationalDiabetesPage;