import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft, ChevronRight, Star } from 'lucide-react';
import { Award } from 'lucide-react';

const testimonials = [
  {
    avatarImage: "/images/avatar1.png",
    name: "Yogesh Arya",
    rating: 5,
    review: "First of all, I would like to thank Dr Swati K for helping us all through our journey under her care. She remained very reachable and empathetic towards our concerns. Apart from being an excellent clinician she is an awesome human being. I know her as a Pediatric Endocrinologist, but her knowledge and experience in treating GDM is also remarkable and full of hopes. She is excellent in taking care of GDM cases also, just like her expertise in Pediatric field. I wish her all the very best and best of health, so that she could take care of all who come under her circle of care. God bless you mam."
  },
  {
    avatarImage: "/images/avatar2.png",
    name: "Prashant Tiwari",
    rating: 5,
    review: "Dr.Swati Kanodia takes time in listening to concerns, took wonderful care of kids, explaining things carefully to make certain kids understood. Parents feel like they get plenty of time to explain their issues without feeling rushed. She is caring and her utmost concern is your kid. Her international exposure to the subject (diabetes, endocrinology etc.) makes her more humble. Overall, we find her smart, gentle and nice person and of course, she does a good job as so professional."
  },
  {
    avatarImage: "/images/avatar3.png",
    name: "Dr. Lohit Gaur",
    rating: 5,
    review: "Dr. Swati Kanodia is a determined clinician with a great sense of responsibility and purpose. She is well versed with great clinical skills and knowledge in the field Paediatric Endocrinology. She is very calm and soft spoken. With good communication skills, she is an extemporaneous speaker, with many publications published in her name in the field of Paediatric Endocrinology. In short, your Kid is in safe hands if ever you need to visit her for any hormone related problem."
  },
  {
    avatarImage: "/images/avatar4.png",
    name: "Mahesh Arora",
    rating: 5,
    review: "We stay in Dubai and recently arrived in Delhi. Google helped me in finding the best Pediatric Endocrinologist as Dr. Swati. Frankly , we loved the thorough and deep approach of Dr. Swati and really appreciate the way she explained and listened us. Thank you for the good treatment and guidance Dr. Swati....👍☺"
  },
  {
    avatarImage: "/images/avatar5.png",
    name: "Rohan Khaneja",
    rating: 5,
    review: "Dr.Swati Kanodia is the best pediatric endocrinologist. She is so kind to the patient,listens to the problem carefully and gives medications accordingly.She is so knowledgeable and an experienced doctor. She gives the best possible ways to cure the problem."
  }
];

const TestimonialCarousel: React.FC = () => {
  const[currentIndex, setCurrentIndex] = useState(0);
  const [containerHeight, setContainerHeight] = useState('auto');

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Find the height of the tallest testimonial
    const maxHeight = Math.max(...testimonials.map(t => {
      const el = document.createElement('div');
      el.innerHTML = t.review;
      document.body.appendChild(el);
      const height = el.offsetHeight;
      document.body.removeChild(el);
      return height;
    }));

    // Set the container height to the maximum height plus some padding
    setContainerHeight(`${maxHeight + 200}px`);
  }, []);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  return (
    <motion.section
      id="testimonials"
      className="bg-[#faf9f5] py-20 px-4 sm:px-6 lg:px-8 overflow-hidden"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="max-w-6xl mx-auto">
        <h2 className="text-3xl md:text-5xl text-purple-900 font-fraunces-slab text-center mb-12">
          What Our Patients Say
        </h2>
        <div className="flex flex-col lg:flex-row gap-10 relative">
          <div className="w-full lg:w-1/3 aspect-square lg:aspect-auto">
            <img
              src="/images/testimonial.jpg"
              alt="Dr. Swati Kanodia"
              className="w-full h-full object-cover rounded-full shadow-2xl"
            />
          </div>
          <div className="w-full lg:w-2/3 relative" style={{ height: containerHeight }}>
            <AnimatePresence mode="wait">
              <motion.div
                key={currentIndex}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -50 }}
                transition={{ duration: 0.5 }}
                className="bg-white rounded-lg shadow-xl p-6 md:p-8 absolute inset-0"
              >
                <div className="flex flex-col h-full justify-between">
                  <div>
                    <div className="flex items-center mb-4">
                      <img
                        src={testimonials[currentIndex].avatarImage}
                        alt={testimonials[currentIndex].name}
                        className="w-16 h-16 rounded-full mr-4"
                      />
                      <div>
                        <h3 className="text-xl font-semibold text-gray-900">
                          {testimonials[currentIndex].name}
                        </h3>
                        <div className="flex items-center">
                          {[...Array(testimonials[currentIndex].rating)].map((_, i) => (
                            <Star key={i} className="w-5 h-5 text-yellow-400 fill-current" />
                          ))}
                        </div>
                      </div>
                    </div>
                    <p className="text-gray-600 italic mb-4">
                      "{testimonials[currentIndex].review}"
                    </p>
                  </div>
                  <div className="flex justify-between items-center mt-4">
                    <button
                      onClick={handlePrev}
                      className="bg-white rounded-full p-2 shadow-md hover:bg-gray-100 transition-colors duration-200"
                      aria-label="Previous testimonial"
                    >
                      <ChevronLeft className="w-6 h-6 text-gray-600" />
                    </button>
                    <div className="flex space-x-2">
                      {testimonials.map((_, index) => (
                        <button
                          key={index}
                          onClick={() => setCurrentIndex(index)}
                          className={`w-3 h-3 rounded-full ${index === currentIndex ? 'bg-purple-600' : 'bg-gray-300'}`}
                          aria-label={`Go to testimonial ${index + 1}`}
                        />
                      ))}
                    </div>
                    <button
                      onClick={handleNext}
                      className="bg-white rounded-full p-2 shadow-md hover:bg-gray-100 transition-colors duration-200"
                      aria-label="Next testimonial"
                    >
                      <ChevronRight className="w-6 h-6 text-gray-600" />
                    </button>
                  </div>
                </div>
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
        <motion.div
          className="w-full md:w-3/4 lg:w-2/3 mx-auto mt-16 py-6 px-2"
        >
          <motion.div
            className="flex flex-col md:flex-row justify-between items-center space-y-16 md:space-y-0 md:space-x-8 md:py-6 md:px-8 md:rounded-lg md:shadow-md ring-zinc-200 backdrop-blur-xl"
            whileHover={{ scale: 1.02 }}
            transition={{ type: "spring", stiffness: 300 }}
          >
            <motion.div
              className="text-center"
              whileHover={{ y: -5 }}
            >
              <p className="text-3xl md:text-4xl font-bold text-zinc-900">10+</p>
              <p className="text-base md:text-lg text-gray-600">Years Experience</p>
            </motion.div>
            <motion.div
              className="text-center"
              whileHover={{ y: -5 }}
            >
              <div className="flex items-center justify-center mb-1">
                <Star className="w-6 h-6 md:w-8 md:h-8 text-yellow-400 fill-current" />
                <span className="text-2xl md:text-3xl font-bold ml-2">5</span>
              </div>
              <div className="flex items-center justify-center">
                <span className="text-2xl md:text-3xl font-bold">
                  <span className="text-blue-600">G</span>
                  <span className="text-red-600">o</span>
                  <span className="text-yellow-600">o</span>
                  <span className="text-blue-600">g</span>
                  <span className="text-green-600">l</span>
                  <span className="text-red-600">e</span>
                </span>
              </div>
            </motion.div>
            <motion.div
              className="text-center"
              whileHover={{ y: -5 }}
            >
              <Award className="w-10 h-10 md:w-12 md:h-12 text-[#4B0082] mx-auto mb-2" />
              <p className="text-base md:text-lg text-gray-600">ESPE Fellowship</p>
            </motion.div>
          </motion.div>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default TestimonialCarousel;