// Define common interfaces

interface BaseService {
    id: string;
    name: string;
    description: string;
}

// Group 1: Diabetes Clinic

export interface DiabetesService extends BaseService {
    type: 'diabetes';
    appointmentDuration: number; // in minutes
    pdfUrl: string; 
}

interface EducatorService extends BaseService {
    type: 'educator';
    specialization: string[];
}

// Group 2: Tests

interface TestProcedure {
    step: string;
    duration: number; // in minutes
}

interface TestService extends BaseService {
    type: 'test';
    procedures: TestProcedure[];
    precautions: string[];
    sideEffects: string[];
}

// Group 3: Treatments

interface TreatmentService extends BaseService {
    type: 'treatment';
    duration: string;
    followUpFrequency: string;
    potentialSideEffects: string[];
}

// Group 4: Holistic Management

interface HolisticService extends BaseService {
    type: 'holistic';
    components: string[];
    programDuration: string;
}

// Union type for all services
export type SpecialtyService = DiabetesService | EducatorService | TestService | TreatmentService | HolisticService;

// Define the groups
export interface ServiceGroup {
    groupName: string;
    services: SpecialtyService[];
}

// Create the specialty services data
const specialtyServices: ServiceGroup[] = [
    {
        groupName: "Diabetes Management",
        services: [
            {
                id: "diabetes-clinic",
                name: "Diabetes Clinic",
                description: "Comprehensive diabetes management and care",
                type: "diabetes",
                appointmentDuration: 60,
                pdfUrl: "https://example.com/diabetes-clinic-info.pdf"

            },
           
        ]
    },
    {
        groupName: "Diagnostic Tests",
        services: [
            {
                id: "growth-hormone-test",
                name: "Growth Hormone Stimulation Test",
                description: "Test to assess growth hormone deficiency",
                type: "test",
                procedures: [
                    { step: "Fasting", duration: 480 },
                    { step: "Baseline blood draw", duration: 10 },
                    { step: "Administration of stimulant", duration: 5 },
                    { step: "Subsequent blood draws", duration: 90 }
                ],
                precautions: ["Fasting required", "Avoid strenuous activity before test"],
                sideEffects: ["Mild nausea", "Dizziness"]
            },
            {
                id: "acth-stimulation-test",
                name: "ACTH Stimulation Test",
                description: "Test to assess adrenal gland function",
                type: "test",
                procedures: [
                    { step: "Baseline blood draw", duration: 10 },
                    { step: "Administration of synthetic ACTH", duration: 5 },
                    { step: "Subsequent blood draws", duration: 60 }
                ],
                precautions: ["Inform doctor of all medications", "May need to adjust steroid medications"],
                sideEffects: ["Temporary flushing", "Mild discomfort at injection site"]
            },
            {
                id: "gnrh-stimulation-test",
                name: "GnRH Stimulation Test",
                description: "Test to evaluate puberty disorders and pituitary gland function",
                type: "test",
                procedures: [
                    { step: "Baseline blood draw", duration: 10 },
                    { step: "Administration of GnRH agonist", duration: 5 },
                    { step: "Multiple blood draws", duration: 120 }
                ],
                precautions: ["Fasting may be required", "Inform doctor of any allergies"],
                sideEffects: ["Temporary skin irritation", "Mild headache"]
            },
            {
                id: "water-deprivation-test",
                name: "Water Deprivation Test",
                description: "Test to diagnose diabetes insipidus and assess kidney function",
                type: "test",
                procedures: [
                    { step: "Initial measurements (weight, urine, blood)", duration: 20 },
                    { step: "Water deprivation period", duration: 480 },
                    { step: "Hourly measurements", duration: 480 },
                    { step: "Administration of vasopressin (if needed)", duration: 10 },
                    { step: "Final measurements", duration: 60 }
                ],
                precautions: ["No food or water during test", "Close monitoring required"],
                sideEffects: ["Dehydration", "Dizziness", "Increased thirst"]
            }
        ]
    },
    {
        groupName: "Treatments",
        services: [
            {
                id: "growth-hormone-treatment",
                name: "Growth Hormone Treatment",
                description: "Long-term treatment to promote growth",
                type: "treatment",
                duration: "Several years",
                followUpFrequency: "Every 3-6 months",
                potentialSideEffects: ["Headache", "Joint pain", "Edema"]
            },
            {
                id: "early-puberty-treatment",
                name: "Early Puberty (GnRH Analog) Treatment",
                description: "Treatment to delay puberty in children with precocious puberty",
                type: "treatment",
                duration: "Until appropriate age for puberty",
                followUpFrequency: "Every 3-4 months",
                potentialSideEffects: ["Initial increase in puberty signs", "Mood changes"]
            }
        ]
    },
    {
        groupName: "Holistic Management",
        services: [
            {
                id: "obesity-management",
                name: "Holistic Obesity Management",
                description: "Comprehensive approach to managing obesity in children and adolescents",
                type: "holistic",
                components: ["Nutritional counseling", "Physical activity planning", "Behavioral therapy", "Family involvement"],
                programDuration: "6-12 months"
            }
        ]
    }
];

export { specialtyServices};