import React from 'react';
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from '../components/ui/accordion';
import { Download, ChevronRight, AlertCircle } from 'lucide-react';

const DietHandout = () => {
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = '/diet.pdf';
    link.download = 'diet-handout.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="max-w-4xl mx-auto p-6 mt-28 md:mt-48">
      {/* Hero Section */}
      <div className="mb-8 bg-white rounded-lg shadow-md overflow-hidden">
        <div className="text-center bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-t-lg">
          <h1 className="text-3xl font-bold text-blue-800">
            Healthy Diet Guidelines for Children
          </h1>
          <p className="text-gray-600 mt-2">
            Expert recommendations for nurturing your child's health through proper nutrition
          </p>
        </div>
      </div>

      {/* Important Notice */}
      <div className="flex items-center gap-2 p-4 bg-yellow-50 rounded-lg mb-8">
        <AlertCircle className="text-yellow-500" />
        <p className="text-sm text-yellow-700">
          Begin eating healthy as a family. Remember: dietary changes require gradual implementation for lasting habits.
        </p>
      </div>

      {/* Main Content Accordions */}
      <Accordion type="single" collapsible>
        <AccordionItem value="foods-to-avoid">
          <AccordionTrigger>
            <span className="text-lg font-semibold">Foods to Avoid</span>
          </AccordionTrigger>
          <AccordionContent>
            <div className="grid md:grid-cols-2 gap-6 p-4">
              <div>
                <h4 className="font-semibold mb-2">Fruits</h4>
                <ul className="list-disc pl-5 space-y-1">
                  <li>Mango</li>
                  <li>Litchi</li>
                  <li>Grapes</li>
                  <li>Cheeku</li>
                  <li>Banana</li>
                </ul>
              </div>
              <div>
                <h4 className="font-semibold mb-2">Vegetables</h4>
                <ul className="list-disc pl-5 space-y-1">
                  <li>Potato</li>
                  <li>Sweet potato</li>
                  <li>Yams</li>
                  <li>Beetroot</li>
                </ul>
              </div>
            </div>
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value="healthy-snacks">
          <AccordionTrigger>
            <span className="text-lg font-semibold">Healthy Snack Options</span>
          </AccordionTrigger>
          <AccordionContent>
            <div className="grid md:grid-cols-2 gap-6 p-4">
              <ul className="list-disc pl-5 space-y-1">
                <li>Sprout chat/ Chana chat</li>
                <li>Vegetable idli</li>
                <li>Homemade dhokla</li>
                <li>Vegetable kathi roll</li>
                <li>Boiled egg</li>
              </ul>
              <ul className="list-disc pl-5 space-y-1">
                <li>Roasted makhana (Fox nuts)</li>
                <li>Fresh fruit shakes (no added sugar)</li>
                <li>Vegetable/clear soup</li>
                <li>Nuts- 5 almonds + 1 walnut</li>
              </ul>
            </div>
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value="daily-routine">
          <AccordionTrigger>
            <span className="text-lg font-semibold">Daily Diet Routine</span>
          </AccordionTrigger>
          <AccordionContent>
            <ul className="list-disc pl-5 space-y-2 p-4">
              <li>Drink 2 glasses of water prior to every meal</li>
              <li>Eat a bowl of salad before starting your meal (Lunch & Dinner)</li>
              <li>Eat a big bowl of curd with every meal</li>
              <li>2-3 liters of water per day</li>
              <li>Avoid only rice based meal</li>
            </ul>
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value="key-reminders">
          <AccordionTrigger>
            <span className="text-lg font-semibold">Key Reminders</span>
          </AccordionTrigger>
          <AccordionContent>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 p-4">
              {[
                { number: 5, label: "Fruit & Veg Servings", bg: "bg-blue-50", textColor: "text-blue-600" },
                { number: 2, label: "Max TV Hours", bg: "bg-green-50", textColor: "text-green-600" },
                { number: 1, label: "Hour Exercise", bg: "bg-purple-50", textColor: "text-purple-600" },
                { number: 0, label: "Junk Food", bg: "bg-red-50", textColor: "text-red-600" }
              ].map((item, index) => (
                <div key={index} className={`${item.bg} rounded-lg p-4 text-center`}>
                  <p className={`text-3xl font-bold ${item.textColor}`}>{item.number}</p>
                  <p className="text-sm">{item.label}</p>
                </div>
              ))}
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      {/* Download and CTA Section */}
      <div className="flex flex-col md:flex-row gap-4 justify-center items-center mt-8">
        <button 
          onClick={handleDownload}
          className="flex items-center px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
        >
          <Download className="mr-2 h-4 w-4" /> Download Diet Guide
        </button>
        
        
      </div>

      {/* CTA Card */}
      <div className="mt-8 bg-gradient-to-r from-blue-500 to-blue-600 text-white rounded-lg shadow-md">
        <div className="flex flex-col md:flex-row items-center justify-between p-6">
          <div>
            <h3 className="text-xl font-bold mb-2">Want Personalized Diet Guidance?</h3>
            <p className="text-blue-100">Schedule an appointment with Dr. Swati Kanodia for expert pediatric nutrition advice.</p>
          </div>
          <button 
            className="mt-4 md:mt-0 bg-white text-blue-600 px-4 py-2 rounded hover:bg-blue-50 transition-colors flex items-center"
            onClick={() => window.location.href = '/contact'}
          >
            Contact Now <ChevronRight className="ml-2 h-4 w-4" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default DietHandout;