import React from 'react';
import { BlogPost, blogPosts } from '../data/blogs';
import { Calendar, Tag, ChevronLeft } from 'lucide-react';
import { Link, useParams } from 'react-router-dom';

const BlogPostTemplate: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const post = blogPosts.find((post) => post.slug === slug);

  if (!post) {
    return <div className="text-center py-10">Blog post not found</div>;
  }

  return (
    <main className="max-w-4xl mx-auto px-4 py-8 mt-28 md:mt-48">
      <nav className="mb-8">
        <Link to="/patient-education" className="text-blue-600 hover:text-blue-800 flex items-center">
          <ChevronLeft className="mr-1" size={20} />
          Back to all posts
        </Link>
      </nav>

      <article>
        <h1 className="text-4xl font-bold mb-4">{post.title}</h1>
        
        <div className="flex items-center text-gray-600 mb-6">
          <Calendar size={18} className="mr-2" />
          <span className="mr-4">{post.date}</span>
          
        </div>

        <div className="w-full flex-shrink-0 flex items-center justify-center mb-6 md:mb-16">
          <img 
            src={post.image} 
            alt={post.title} 
            className="w-full md:w-72 h-full object-contain" 
          />
        </div>

        <div className="prose max-w-none">
          <h2 className="text-2xl font-semibold mb-4">{post.heading}</h2>
          
          <p className="mb-6">{post.content}</p>

          {post.paragraphs.map((paragraph, index) => (
            <p key={index} className="mb-4">{paragraph}</p>
          ))}

          <h3 className="text-xl font-semibold mb-3 mt-6">Key Points:</h3>
          <ul className="list-disc pl-6 mb-6">
            {post.subheadings.map((subheading, index) => (
              <li key={index}>{subheading}</li>
            ))}
          </ul>
        </div>

        <div className="mt-8">
          <h4 className="text-lg font-semibold mb-2">Tags:</h4>
          <div className="flex flex-wrap gap-2">
            {post.tags.map((tag, index) => (
              <span key={index} className="bg-gray-200 text-gray-800 px-3 py-1 rounded-full text-sm">
                <Tag size={14} className="inline mr-1" />
                {tag}
              </span>
            ))}
          </div>
        </div>
      </article>
    </main>
  );
};

export default BlogPostTemplate;