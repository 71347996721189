import React, { useState } from 'react';
import { Link } from 'react-router-dom'; 

interface TextItem {
    text: string;
    link: string;
}

interface TextCarouselProps {
    items: TextItem[];
    speed: number;
}

const TextCarousel: React.FC<TextCarouselProps> = ({ items, speed = 30 }) => {
    const [isPaused, setIsPaused] = useState(false);

    return (
        <div 
            className="overflow-hidden whitespace-nowrap py-2"
            onMouseEnter={() => setIsPaused(true)}
            onMouseLeave={() => setIsPaused(false)}
        >
            <div
                className={`inline-block ${isPaused ? '' : 'animate-marquee'}`}
                style={{ animationDuration: `${speed}s`, animationPlayState: isPaused ? 'paused' : 'running' }}
            >
                {items.concat(items).map((item, index) => (
                    <Link 
                        key={index} 
                        to={item.link}
                        className="mx-8 text-sm md:text-lg lg:text-2xl font-bold font-work-sans-slab text-white hover:underline mb-4"
                    >
                        {item.text} 
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default TextCarousel;