import React from 'react';
import { DiabetesService } from '../data/specailtyservices';

interface CardProps {
  children: React.ReactNode;
  className?: string;
}

export const Card: React.FC<CardProps> = ({ children, className = '' }) => (
  <div className={`bg-white shadow-md rounded-lg ${className}`}>{children}</div>
);

export const CardHeader: React.FC<CardProps> = ({ children, className = '' }) => (
  <div className={`px-6 py-4 border-b ${className}`}>{children}</div>
);

export const CardContent: React.FC<CardProps> = ({ children, className = '' }) => (
  <div className={`px-6 py-4 ${className}`}>{children}</div>
);


export const DiabetesServiceCard: React.FC<{ service: DiabetesService }> = ({ service }) => (
  <Card>
      <CardHeader>
          <h3 className="text-lg font-medium">{service.name}</h3>
      </CardHeader>
      <CardContent>
          <p>{service.description}</p>
          <p>Appointment Duration: {service.appointmentDuration} minutes</p>
          <a href={service.pdfUrl} className="mt-4 inline-block bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded">
              Download PDF
          </a>
      </CardContent>
  </Card>
);