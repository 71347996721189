import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { Pen, Mail, Download } from 'lucide-react';
import ScatteredBackground from '../components/ui/ScatteredBackground';


const ImageCarousel: React.FC<{ images: string[], captions: string[] }> = ({ images, captions }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 3000); // Change slide every 3 seconds

        return () => clearInterval(interval);
    }, [images.length]);

    return (
        <div className="relative w-full h-64 overflow-hidden rounded-lg">
            {images.map((image, index) => (
                <div
                    key={index}
                    className={`absolute w-full h-full transition-opacity duration-500 ${index === currentIndex ? 'opacity-100' : 'opacity-0'
                        }`}
                >
                    <img src={image} alt={captions[index]} className="w-full h-full object-cover" />
                    <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-2 text-center">
                        {captions[index]}
                    </div>
                </div>
            ))}
        </div>
    );
};

const About: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const openModal = () => setIsModalOpen(true);
    console.log(isModalOpen);


    const education = [
        'MBBS from M.S.Ramaiah Medical College, Bangalore',
        'MD (Paediatrics) from Dr.D.Y.Patil Medical College, Navi Mumbai',
        'Fellowship in Paediatric and Adolescent Endocrinology and Diabetes from GRIPMER, Sir Ganga Ram Hospital, Delhi',
        'Postgraduate Diploma in Diabetes (Distinction) from Cardiff University, Wales, UK',
        'European Society of Paediatric Endocrinology (ESPE) Clinical Fellowship from Alder Hey Children\'s Hospital, Liverpool, UK'
    ];

    const affiliations = [
        'BLK-MAX Super Specialty Hospital, Delhi',
        'Madhukar Rainbow Children\'s Hospital & BirthRight, Delhi',
        'Aartas Clinishare, Delhi',
        'Children\'s Multispecialty Clinic, Delhi',
        'Kidsendo clinic - Gauri hospital, Delhi',
        'Apollo Cradle, , Moti Nagar, Delhi'
    ];

    const specializations = [
        'Gestational Diabetes',
        'Growth problems - short stature/tall stature',
        'Obesity',
        'Pubertal issues - Early or Delayed puberty',
        'Diabetes (Type1, Type 2, Neonatal, Monogenic)',
        'Hypothyroidism /Hyperthyroidism/Goiter',
        'Ambiguous Genitalia',
        'Paediatric Bone Diseases'
    ];

    const educationImages = [
        '/images/about/msramaiah.jpg',
        '/images/about/dypatil.jpg',
        '/images/about/ganga-ram.jpg',
        '/images/about/cardiff.webp',
        '/images/about/alder-hey.jpg',
    ];

    const educationCaptions = [
        'M.S. Ramaiah Medical College',
        'Dr. D.Y. Patil Medical College',
        'Sir Ganga Ram Hospital',
        'Cardiff University',
        'Alder Hey Children\'s Hospital',
    ];

    const affiliationImages = [
        '/images/about/blk-max.webp',
        '/images/about/rainbow.png',
        '/images/about/aartas.png',
        '/images/about/multispecialty.jpg',
        '/images/about/gauri.webp',
        '/images/about/apollo-cradle.webp'
    ];

    const affiliationCaptions = [
        'BLK-MAX Super Specialty Hospital',
        'Madhukar Rainbow Children\'s Hospital',
        'Aartas Clinishare',
        'Children\'s Multispecialty Clinic',
        'Kidsendo clinic - Gauri hospital',
        'Apollo Cradle'
    ];

    return (
        <ScatteredBackground lightColor="#fff" darkColor="#d6d4d6">
            <main className="min-h-screen relative overflow-hidden ">
                <Helmet>
                    <title>Dr. Swati Kanodia - Pediatric & Adolescent Endocrinologist | About</title>
                    <meta name="description" content="Dr. Swati Kanodia is a leading Pediatric & Adolescent Endocrinologist with over 10 years of experience. Specializing in growth disorders, diabetes, and hormonal imbalances in children." />
                </Helmet>


                <div className="relative">
                    <section className="relative mt-28 md:mt-48">
                        <div className="relative w-full h-screen max-h-screen overflow-hidden">
                            <motion.img
                                src="/images/about/about-hero.png"
                                alt="Dr. Swati Kanodia"
                                className="absolute w-full h-full object-cover object-center"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5 }}
                            />
                            <div className="absolute inset-0 bg-gradient-to-b from-black/70 to-black/40 flex flex-col justify-center items-center text-center px-4 sm:px-8 md:px-12 lg:px-16">
                                <motion.div
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.8, delay: 0.2 }}
                                    className="max-w-4xl mx-auto"
                                >
                                    <h1 className="text-3xl md:text-4xl lg:text-6xl font-pt-serif-regular text-white text-center shadow-text">
                                        Meet Dr. Swati Kanodia
                                    </h1>
                                </motion.div>
                            </div>
                        </div>
                    </section>

                    <div className="container mx-auto px-4 mt-10">

                        <motion.div
                            className="bg-white shadow-xl rounded-lg overflow-hidden mb-12 -mt-32 relative z-10"
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6 }}
                        >
                            <div className="md:flex">
                                <div className="md:w-1/3">
                                    <img
                                        src="/images/about/about-profile.jpg"
                                        alt="Dr. Swati Kanodia"
                                        className="w-full h-full object-cover"
                                    />
                                </div>
                                <div className="md:w-2/3 p-8">
                                    <h2 className="text-3xl md:text-4xl lg:text-6xl font-pt-serif-regular  mb-4 text-purple-800">Dr. Swati Kanodia</h2>
                                    <p className="text-2xl mb-4 text-gray-700">Pediatric & Adolescent Endocrinologist</p>
                                    <p className="text-xl mb-6 text-gray-600">
                                        Experience: <span className="font-bold">10+ years</span>
                                    </p>
                                    <p className="text-lg text-gray-600 leading-relaxed">
                                        Dr. Swati Kanodia is a highly skilled Pediatric & Adolescent Endocrinologist with over a decade of experience. Her patient-centered approach and expertise in treating hormonal disorders in children make her one of the top specialists in Delhi.
                                    </p>
                                    <div className="mt-8 flex space-x-4">
                                        <button
                                            className="inline-flex items-center px-4 py-2 md:px-4 md:py-2 lg:px-6 lg:py-3 text-xs md:text-md lg:text-lg font-semibold text-white bg-purple-600 rounded-lg"
                                            onClick={openModal}
                                        >
                                            Book Appointment
                                            <Pen className="ml-2 w-5 h-5" />
                                        </button>
                                        <a href="mailto:dr.swatikanodia@gmail.com" className="inline-flex items-center justify-center w-12 h-12 bg-purple-600 rounded-full">
                                            <Mail className="w-6 h-6 text-white" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </motion.div>

                    </div>
                </div>

                <div className="container mx-auto px-4 py-12">
                    <motion.section
                        className="mb-16 bg-white rounded-lg shadow-lg p-8"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, delay: 0.2 }}
                    >
                        <h2 className="text-3xl md:text-4xl lg:text-6xl font-pt-serif-regular  mb-8 text-center text-purple-800">Areas of Expertise</h2>
                        <div className="grid md:grid-cols-2 gap-8">
                            {specializations.map((spec, index) => (
                                <div key={index} className="flex items-start">
                                    <div className="flex-shrink-0 w-12 h-12 rounded-full bg-purple-100 flex items-center justify-center">
                                        <svg className="w-6 h-6 text-purple-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </div>
                                    <div className="ml-4">
                                        <h3 className="text-lg md:text-xl text-gray-800">{spec}</h3>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </motion.section>
                    <motion.section
                        className="mb-16 bg-white rounded-lg shadow-lg p-8"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, delay: 0.4 }}
                    >
                        <h2 className="text-3xl md:text-4xl lg:text-6xl font-pt-serif-regular mb-8 text-center text-purple-800">Educational Qualifications</h2>
                        <div className="grid md:grid-cols-2 gap-8">
                            <div className="md:order-2">
                                <ImageCarousel images={educationImages} captions={educationCaptions} />
                            </div>
                            <div className="md:order-1">
                                <ul className="space-y-4">
                                    {education.map((edu, index) => (
                                        <li key={index} className="flex items-center">
                                            <svg
                                                className="flex-shrink-0 w-6 h-6 min-w-[24px] min-h-[24px] text-purple-600 mr-2"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                            <span className="text-md md:text-lg text-gray-700">{edu}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </motion.section>

                    <motion.section
                        className="mb-16 bg-white rounded-lg shadow-lg p-8"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, delay: 0.6 }}
                    >
                        <h2 className="text-3xl md:text-4xl lg:text-6xl font-pt-serif-regular mb-8 text-center text-purple-800">Hospital Affiliations</h2>
                        <div className="grid md:grid-cols-2 gap-8">
                            <div>
                                <ul className="space-y-4">
                                    {affiliations.map((affiliation, index) => (
                                        <li key={index} className="flex items-center">
                                            <svg
                                                className="flex-shrink-0 w-6 h-6 min-w-[24px] min-h-[24px] text-purple-600 mr-2"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                                            </svg>
                                            <span className="text-md md:text-lg text-gray-700">{affiliation}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div>
                                <ImageCarousel images={affiliationImages} captions={affiliationCaptions} />
                            </div>
                        </div>
                    </motion.section>

                    <motion.div
                        className="bg-white shadow-lg rounded-lg overflow-hidden"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, delay: 0.8 }}
                    >
                        <div className="md:flex">
                            <div className="md:w-1/2 p-8">
                                <h3 className="text-3xl md:text-4xl lg:text-6xl font-pt-serif-regular mb-4 text-purple-800">Dr. Kanodia's Approach</h3>
                                <p className="text-gray-700 mb-4 leading-relaxed">
                                    Dr. Swati Kanodia believes in a holistic approach to pediatric endocrine disorders. She combines the latest medical advancements with personalized care to ensure the best outcomes for her young patients.
                                </p>
                                <p className="text-gray-700 leading-relaxed">
                                    With a focus on patient education and preventive care, Dr. Kanodia empowers both children and their families to take control of their hormonal health and lead fulfilling lives.
                                </p>
                            </div>
                            <div className="md:w-1/2">
                                <img
                                    src="/images/about/about-approach.png"
                                    alt="Dr. Kanodia with a patient"
                                    className="w-full h-full object-cover"
                                />
                            </div>
                        </div>
                    </motion.div>
                </div>
            </main>
        </ScatteredBackground>

    );
};

export default About;