import React, { ReactNode } from 'react';
import { motion } from 'framer-motion';

interface ScatteredBackgroundProps {
  children?: ReactNode;
  lightColor: string;
  darkColor: string;
}

const ScatteredBackground: React.FC<ScatteredBackgroundProps> = ({ children, lightColor, darkColor }) => {
  const bubbles = [
    { size: 300, x: '10%', y: '20%', delay: 0 },
    { size: 200, x: '80%', y: '60%', delay: 0.2 },
    { size: 150, x: '50%', y: '30%', delay: 0.4 },
    { size: 100, x: '70%', y: '80%', delay: 0.6 },
    { size: 80, x: '20%', y: '70%', delay: 0.8 },
    { size: 250, x: '30%', y: '50%', delay: 1.0 },
    { size: 180, x: '60%', y: '40%', delay: 1.2 },
    { size: 120, x: '40%', y: '90%', delay: 1.4 },
    { size: 90, x: '90%', y: '10%', delay: 1.6 },
    { size: 220, x: '5%', y: '85%', delay: 1.8 },
  ];

  // Function to create a semi-transparent version of a color
  const transparentize = (color: string, opacity: number) => {
    return color + Math.round(opacity * 255).toString(16).padStart(2, '0');
  };

  return (
    <div className="relative overflow-hidden" style={{ backgroundColor: lightColor }}>
      {bubbles.map((bubble, index) => (
        <motion.div
          key={index}
          className="absolute rounded-full mix-blend-multiply"
          style={{
            width: bubble.size,
            height: bubble.size,
            left: bubble.x,
            top: bubble.y,
            background: `radial-gradient(circle, ${transparentize(darkColor, 0.7)} 0%, ${transparentize(darkColor, 0.4)} 50%, ${transparentize(darkColor, 0.1)} 100%)`,
          }}
          animate={{
            scale: [1, 1.1, 1],
            opacity: [0.7, 0.9, 0.7],
          }}
          transition={{
            duration: 8,
            repeat: Infinity,
            delay: bubble.delay,
          }}
        />
      ))}
      <div 
        className="absolute inset-0 opacity-30"
        style={{
          backgroundImage: `url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%' height='100%' filter='url(%23noiseFilter)'/%3E%3C/svg%3E")`,
          backgroundSize: '200px 200px',
          mixBlendMode: 'soft-light'
        }}
      />
      <motion.div
        className="absolute inset-0"
        style={{
          background: `linear-gradient(45deg, ${transparentize(darkColor, 0.2)} 0%, ${transparentize(darkColor, 0.2)} 100%)`,
        }}
        animate={{
          opacity: [0.2, 0.3, 0.2],
        }}
        transition={{
          duration: 10,
          repeat: Infinity,
        }}
      />
      {children && <div className="relative z-10">{children}</div>}
    </div>
  );
};

export default ScatteredBackground;