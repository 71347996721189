import React, { useEffect, useRef } from 'react';
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';

const YouTubeVideo = ({ videoId, autoplay = false, controls = true, mute = false }: { videoId: string, autoplay?: boolean, controls?: boolean, mute?: boolean }) => {
    const src = `https://www.youtube.com/embed/${videoId}?autoplay=${autoplay ? 1 : 0}&controls=${controls ? 1 : 0}&mute=${mute ? 1 : 0}`;

    return (
        <div className="aspect-video rounded-lg overflow-hidden shadow-lg h-full">
            <iframe
                title="Youtube"
                src={src}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="w-full h-full"
            ></iframe>
        </div>
    );
};

const PadiatricEndocrinologyIntro = () => {
    const videoRef = useRef(null);
    const [isVideoVisible, setIsVideoVisible] = React.useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsVideoVisible(true);
                } else {
                    setIsVideoVisible(false);
                }
            });
        }, options);

        const currentRef = videoRef.current;
        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, []);

    return (
        <motion.section
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="py-20 relative"
        >
            <div className="container mx-auto px-4">
                <h2 className="text-3xl md:text-4xl font-bold mb-8 text-center font-fraunces-slab">Understanding Pediatric Endocrinology</h2>
                <div className="flex flex-col lg:flex-row gap-8 mb-16">
                    <div className="w-full lg:w-1/2">
                        <p className="text-lg mb-8 font-work-sans">
                            Pediatric Endocrinology is a medical subspecialty focused on diagnosing and treating hormonal disorders in children and adolescents. It encompasses a wide range of conditions affecting growth, puberty, diabetes, and other endocrine-related issues unique to young patients.
                        </p>
                        <p className="text-lg mb-8 font-work-sans">
                            Our team of specialized pediatric endocrinologists is dedicated to providing comprehensive care for children with hormonal imbalances. We utilize the latest diagnostic techniques and treatment approaches to ensure the best possible outcomes for our young patients.
                        </p>
                    </div>
                    <div ref={videoRef} className="w-full lg:w-1/2">
                        <div className="relative rounded-xl overflow-hidden shadow-2xl h-full">
                            <YouTubeVideo
                                videoId="lscI-Ml-O3A"
                                autoplay={isVideoVisible}
                                controls={false}
                                mute={false}
                            />
                        </div>
                    </div>
                </div>
                <div className="text-center mt-8 sm:mt-12">
                    <p className="text-sm md:text-base mb-3 font-work-sans text-gray-600">For more educational videos:</p>
                    <button onClick={() => navigate("/gallery")} className="bg-purple-600 text-white font-pt-serif-regular px-6 py-3 rounded-lg inline-flex items-center justify-center text-md md:text-lg transition-all duration-300 hover:bg-purple-800 hover:scale-105">
                        Explore Gallery & More Such Videos
                    </button>
                </div>
            </div>
        </motion.section>
    );
};

export default PadiatricEndocrinologyIntro;