import React, { useState } from 'react';
import { specialtyServices, SpecialtyService, ServiceGroup } from '../components/data/specailtyservices';
import { motion } from 'framer-motion';
import { Calendar, Video } from 'lucide-react';
import AppointmentModal from '../components/utilities/AppointmentModal';
import { Card, CardHeader, CardContent } from '../components/ui/card';
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from '../components/ui/accordion';
import ScatteredBackground from '../components/ui/ScatteredBackground';
import OfflineModal from '../components/utilities/OfflineModal';
import { Download } from 'lucide-react';


const Services: React.FC = () => {

  const [selectedService, setSelectedService] = useState<SpecialtyService | null>(
    specialtyServices[0].services[0]
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const openModal2 = () => setIsModalOpen2(true);
  const closeModal2 = () => setIsModalOpen2(false);


  const handleServiceClick = (service: SpecialtyService) => {
    setSelectedService(service);
  };

  const getCardColor = (index: number) => {
    const colors = ['bg-blue-100', 'bg-green-100', 'bg-purple-100', 'bg-pink-100'];
    return colors[index % colors.length];
  };


  const handleDownloadPDF = () => {
    // If the PDF is in the public folder, we need to handle the path correctly
    const pdfPath = '/diabetes-patient-info.pdf'; // Use the actual path in your public folder
    const link = document.createElement('a');
    link.href = pdfPath;
    link.download = 'diabetes-information.pdf'; // The name that will appear when downloading
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const renderServiceDetails = (service: SpecialtyService) => {
    return (
      <div>
        <p className="text-gray-700 mb-2">Type: {service.type}</p>
        {service.type === 'diabetes' && (
          <>
            <p className="text-gray-700 mb-2">Info:
              <span className='text-gray-700 mb-2'> Doctor to provide</span>
            </p>

            {/* <button
              onClick={handleDownloadPDF}
              className="inline-flex items-center px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors mb-4"
            >
              <Download className="w-4 h-4 mr-2" />
              Download Diabetes Information PDF
            </button> */}

          </>
        )}
        {service.type === 'educator' && (
          <p className="text-gray-700 mb-2">Specializations: {service.specialization.join(', ')}</p>
        )}
        {service.type === 'test' && (
          <>
            <p className="text-gray-700 mb-2">Info:
              <span className='text-gray-700 mb-2'> Doctor to provide</span>
            </p>

            {/* <ul className="list-disc pl-5 mb-2">
              {service.procedures.map((procedure, index) => (
                <li key={index}>{procedure.step} - {procedure.duration} minutes</li>
              ))}
            </ul>
            <p className="text-gray-700 mb-2">Precautions: {service.precautions.join(', ')}</p>
            <p className="text-gray-700 mb-2">Side Effects: {service.sideEffects.join(', ')}</p> */}
          </>
        )}
        {service.type === 'treatment' && (
          <>
            <p className="text-gray-700 mb-2">Info:
              <span className='text-gray-700 mb-2'> Doctor to provide</span>
            </p>

            {/* <p className="text-gray-700 mb-2">Duration: {service.duration}</p>
            <p className="text-gray-700 mb-2">Follow-up Frequency: {service.followUpFrequency}</p>
            <p className="text-gray-700 mb-2">Potential Side Effects: {service.potentialSideEffects.join(', ')}</p> */}
          </>
        )}
        {service.type === 'holistic' && (
          <>
            {/* <p className="text-gray-700 mb-2">Components: {service.components.join(', ')}</p>
            <p className="text-gray-700 mb-2">Program Duration: {service.programDuration}</p> */}
            <p className="text-gray-700 mb-2">Info:
              <span className='text-gray-700 mb-2'> Doctor to provide</span>
            </p>
          </>
        )}
      </div>
    );
  };

  return (
    <ScatteredBackground lightColor="#F5FFF5" darkColor="#98e3bc">

      <main className='flex-grow'>
        <section className="relative w-full h-screen max-h-screen overflow-hidden">
          <img
            src="/images/services/services-hero.jpg"
            alt="Pediatric Endocrinology Services"
            className="absolute w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-b from-black/70 to-black/40 flex flex-col justify-center items-center text-center px-4 sm:px-8 md:px-12 lg:px-16">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              className="max-w-4xl mx-auto mt-24"
            >
              <h1 className="font-fraunces-slab text-4xl sm:text-5xl md:text-6xl lg:text-7xl mb-4 text-white">
                Specialized Care for Growing Minds and Bodies
              </h1>
              <p className="font-work-sans text-lg md:text-xl lg:text-2xl text-yellow-300 mb-6">
                Pediatric & Adolescent Endocrinology Services
              </p>
              <p className='font-pt-serif-regular text-lg md:text-xl lg:text-2xl text-yellow-300 mb-6'>
                offered by
              </p>
              <p className="text-white text-xl md:text-2xl font-bold mb-12">
                Dr. Swati Kanodia
              </p>

              <div className="flex flex-col sm:flex-row gap-4 justify-center">
                <button
                  onClick={openModal}
                  className="bg-purple-600 text-white font-pt-serif-regular px-6 py-3 rounded-lg flex items-center justify-center text-lg transition-all duration-300 hover:bg-purple-700 hover:scale-105 shadow-lg"
                >
                  Book Appointment
                  <Calendar className="ml-3 w-5 h-5" />
                </button>
                <button
                  onClick={openModal2}
                  className="bg-green-600 text-white font-pt-serif-regular px-6 py-3 rounded-lg flex items-center justify-center text-lg transition-all duration-300 hover:bg-green-700 hover:scale-105 shadow-lg"
                >
                  Virtual Consultation
                  <Video className="ml-3 w-5 h-5" />
                </button>
              </div>
            </motion.div>
          </div>
          <OfflineModal isOpen={isModalOpen} onClose={closeModal} />
          <AppointmentModal isOpen={isModalOpen2} onClose={closeModal2} />
        </section>

        <section className="container mx-auto px-4 py-8 mt-10 md:mt-28">
          <div className="flex flex-col md:flex-row">

            <div className="md:hidden w-full mb-6">
              <Accordion type="single" collapsible>
                {specialtyServices.map((group: ServiceGroup) => (
                  <AccordionItem key={group.groupName} value={group.groupName}>
                    <AccordionTrigger>{group.groupName}</AccordionTrigger>
                    <AccordionContent>
                      <ul>
                        {group.services.map((service: SpecialtyService) => (
                          <li
                            key={service.id}
                            className={`cursor-pointer py-2 px-4 rounded transition-colors ${selectedService?.id === service.id
                              ? 'bg-blue-500 text-white'
                              : 'hover:bg-gray-100'
                              }`}
                            onClick={() => handleServiceClick(service)}
                          >
                            {service.name}
                          </li>
                        ))}
                      </ul>
                    </AccordionContent>
                  </AccordionItem>
                ))}
              </Accordion>
            </div>

            {/* Left-side navigation */}
            <nav className="hidden md:block w-full md:w-1/4 mb-6 md:mb-0 md:pr-4">
              {specialtyServices.map((group: ServiceGroup) => (
                <div key={group.groupName} className="mb-4">
                  <h2 className="font-semibold text-lg mb-2">{group.groupName}</h2>
                  <ul>
                    {group.services.map((service: SpecialtyService) => (
                      <li
                        key={service.id}
                        className={`cursor-pointer py-2 px-4 rounded transition-colors ${selectedService?.id === service.id
                          ? 'bg-blue-500 text-white'
                          : 'hover:bg-gray-100'
                          }`}
                        onClick={() => handleServiceClick(service)}
                      >
                        {service.name}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </nav>

            {/* Right-side content */}
            <main className="w-full md:w-3/4">
              {selectedService && (
                <Card className={`overflow-hidden ${getCardColor(specialtyServices.findIndex(group => group.services.includes(selectedService)))}`}>
                  <CardHeader>
                    <h2 className="text-2xl font-bold">{selectedService.name}</h2>
                  </CardHeader>
                  <CardContent>
                    <p className="text-gray-700 mb-4">{selectedService.description}</p>
                    {renderServiceDetails(selectedService)}
                    <div className="mt-6">
                      <button
                        onClick={openModal}
                        className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors"
                      >
                        Book Appointment
                      </button>
                    </div>
                  </CardContent>
                </Card>
              )}
            </main>
          </div>
        </section>
        <AppointmentModal isOpen={isModalOpen} onClose={closeModal} />
      </main>
    </ScatteredBackground>
  );
};

export default Services;
