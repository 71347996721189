import React, { useState, ReactElement } from 'react';

interface AccordionProps {
  children: ReactElement<AccordionItemProps>[];
  type?: 'single' | 'multiple';
  collapsible?: boolean;
}

export const Accordion: React.FC<AccordionProps> = ({ children, type = 'single', collapsible = false }) => {
  const [openItems, setOpenItems] = useState<string[]>([]);

  const toggleItem = (itemValue: string) => {
    if (type === 'single') {
      setOpenItems(openItems.includes(itemValue) ? [] : [itemValue]);
    } else {
      setOpenItems(
        openItems.includes(itemValue)
          ? openItems.filter((item) => item !== itemValue)
          : [...openItems, itemValue]
      );
    }
  };

  return (
    <div className="space-y-2">
      {React.Children.map(children, (child) => {
        if (React.isValidElement<AccordionItemProps>(child)) {
          return React.cloneElement(child, {
            isOpen: openItems.includes(child.props.value),
            onToggle: () => toggleItem(child.props.value),
            collapsible,
          });
        }
        return child;
      })}
    </div>
  );
};

interface AccordionItemProps {
  children: ReactElement<AccordionTriggerProps | AccordionContentProps>[];
  value: string;
  isOpen?: boolean;
  onToggle?: () => void;
  collapsible?: boolean;
}

export const AccordionItem: React.FC<AccordionItemProps> = ({ children, value, isOpen, onToggle, collapsible }) => (
  <div className="border rounded-md">
    {React.Children.map(children, (child) => {
      if (React.isValidElement<AccordionTriggerProps | AccordionContentProps>(child)) {
        return React.cloneElement(child, { isOpen, onToggle, collapsible });
      }
      return child;
    })}
  </div>
);

interface AccordionTriggerProps {
  children: React.ReactNode;
  isOpen?: boolean;
  onToggle?: () => void;
  collapsible?: boolean;
}

export const AccordionTrigger: React.FC<AccordionTriggerProps> = ({ children, isOpen, onToggle, collapsible }) => (
  <button
    className="w-full text-left py-2 px-4 focus:outline-none"
    onClick={onToggle}
    disabled={isOpen && !collapsible}
  >
    {children}
  </button>
);

interface AccordionContentProps {
  children: React.ReactNode;
  isOpen?: boolean;
}

export const AccordionContent: React.FC<AccordionContentProps> = ({ children, isOpen }) => (
  <div className={`px-4 py-2 ${isOpen ? 'block' : 'hidden'}`}>{children}</div>
);