import React, { useState } from 'react';
import { motion } from 'framer-motion';
import treatmentsData from '../data/treatments';
import { useNavigate } from 'react-router-dom';
import ScatteredBackground from '../ui/ScatteredBackground';

const HomeServices = () => {
  const [selectedTreatment, setSelectedTreatment] = useState(treatmentsData[0]);
  const navigate = useNavigate();

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  return (
    <section className="py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <motion.h2 
          className="text-3xl md:text-4xl font-bold text-purple-800 mb-8 text-center"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Our Services and Treatments
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <motion.div 
            className=" md:col-span-1 bg-white rounded-lg shadow-lg p-6 overflow-y-auto max-h-[300px] md:max-h-[600px]"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            {treatmentsData.map((treatment, index) => (
              <motion.div
                key={treatment.slug}
                className={`cursor-pointer p-3 rounded-md mb-2 transition-colors ${selectedTreatment.slug === treatment.slug ? 'bg-purple-100' : 'hover:bg-purple-50'}`}
                onClick={() => setSelectedTreatment(treatment)}
                variants={itemVariants}
              >
                <h3 className="font-semibold text-purple-700">{treatment.title}</h3>
              </motion.div>
            ))}
          </motion.div>
          <motion.div 
            className="md:col-span-2 bg-white rounded-lg shadow-lg p-6"
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className="flex flex-col sm:flex-row items-center mb-6">
              <img 
                src={selectedTreatment.image} 
                alt={selectedTreatment.title} 
                className="w-full sm:w-1/3 h-48 object-cover rounded-lg mb-4 sm:mb-0 sm:mr-6"
              />
              <div>
                <h3 className="text-2xl font-bold text-purple-800 mb-2">{selectedTreatment.title}</h3>
                <p className="text-gray-600">{selectedTreatment.description}</p>
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-6">
              <div>
                <h4 className="font-semibold text-purple-700 mb-2">Symptoms</h4>
                <ul className="list-disc list-inside text-gray-600">
                  {selectedTreatment.symptoms.map((symptom, index) => (
                    <li key={index}>{symptom}</li>
                  ))}
                </ul>
              </div>
              <div>
                <h4 className="font-semibold text-purple-700 mb-2">Treatments</h4>
                <ul className="list-disc list-inside text-gray-600">
                  {selectedTreatment.treatments.map((treatment, index) => (
                    <li key={index}>{treatment}</li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row justify-end space-y-4 sm:space-y-0 sm:space-x-4">
              <button
               onClick={()=>navigate(`/treatments/${selectedTreatment.slug}`)}
               className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300 ease-in-out w-full sm:w-auto">
                Full Info
              </button>
              <button className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300 ease-in-out w-full sm:w-auto">
                Book Appointment
              </button>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default HomeServices;
