import React, { useState, useRef, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { motion } from 'framer-motion';
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaStethoscope } from 'react-icons/fa';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useForm, ValidationError } from '@formspree/react';
import { Video, Calendar } from 'lucide-react';
//import SEOMetaTags from '../components/utility/SEOMetaTags';
//import ScatteredPurpleBackground from '../ScatteredPurpleBackground';
import AppointmentModal from '../components/utilities/AppointmentModal';

import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import OfflineModal from '../components/utilities/OfflineModal';

const customIcon = L.icon({
    iconUrl: markerIcon,
    iconRetinaUrl: markerIcon2x,
    shadowUrl: markerShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
});



const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const position: [number, number] = [28.68026700443246, 77.20826121534358]; // Approximate coordinates for New Delhi
    const mapRef = useRef<HTMLDivElement>(null);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    const openModal2 = () => setIsModalOpen2(true);
    const closeModal2 = () => setIsModalOpen2(false);


    const scrollToMap = () => {
        mapRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const [state, handleSubmit] = useForm('xovagdyp');

    return (
        <>

            <main className='flex-grow'>

                <div className="relative mt-28 lg:mt-40">
                    <section className="relative">
                        <div className="relative w-full h-screen max-h-screen overflow-hidden">
                            <motion.img
                                src="/images/contact/contact-hero.jpg"
                                alt="Contact Dr. Swati Kanodia"
                                className="absolute w-full h-full object-cover object-center"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5 }}
                            />
                            <div className="absolute inset-0 bg-gradient-to-b from-black/70 to-black/40 flex flex-col justify-center items-center text-center px-4 sm:px-8 md:px-12 lg:px-16">
                                <motion.div
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.8, delay: 0.2 }}
                                    className="max-w-4xl mx-auto"
                                >
                                    <h1 className="text-3xl md:text-4xl lg:text-6xl font-pt-serif-regular text-white mb-8 shadow-text">
                                        Connect with Dr. Swati Kanodia
                                    </h1>
                                    <div className="flex flex-col sm:flex-row gap-4 justify-center">
                                        <button
                                            onClick={openModal}
                                            className="bg-purple-600 text-white font-pt-serif-regular px-6 py-3 rounded-lg flex items-center justify-center text-lg transition-all duration-300 hover:bg-purple-700 hover:scale-105 shadow-lg"
                                        >
                                            Book Appointment
                                            <Calendar className="ml-3 w-5 h-5" />
                                        </button>
                                        <button
                                            onClick={openModal2}
                                            className="bg-green-600 text-white font-pt-serif-regular px-6 py-3 rounded-lg flex items-center justify-center text-lg transition-all duration-300 hover:bg-green-700 hover:scale-105 shadow-lg"
                                        >
                                            Virtual Consultation
                                            <Video className="ml-3 w-5 h-5" />
                                        </button>
                                    </div>
                                </motion.div>
                            </div>
                        </div>
                        <OfflineModal isOpen={isModalOpen} onClose={closeModal} />
                        <AppointmentModal isOpen={isModalOpen2} onClose={closeModal2} />
                    </section>

                    <div ref={mapRef} className="container mx-auto px-4 mt-2">
                        <motion.div className="shadow-xl rounded-lg overflow-hidden mb-12 -mt-32 relative z-10">
                            <motion.div className="h-96 rounded-lg overflow-hidden shadow-lg">
                                <MapContainer center={position} zoom={15} style={{ height: '400px', width: '100%' }}>
                                    <TileLayer
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    />
                                    <Marker position={position} icon={customIcon}>
                                        <Popup>
                                            Dr. Swati Kanodia
                                        </Popup>
                                    </Marker>
                                </MapContainer>
                            </motion.div>
                        </motion.div>
                    </div>
                </div>


                <section className="contact-info py-24 bg-gray-100">
                    <motion.div className="container mx-auto px-4">
                        <h2 className="text-3xl md:text-4xl lg:text-6xl font-pt-serif-regular text-center mb-16 text-purple-800">Get in Touch with Dr. Swati Kanodia</h2>
                        <motion.div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                            <ContactItem
                                icon={<FaMapMarkerAlt className="text-blue-500" />}
                                title="Address"
                                content="Kidsendo Clinic, Gauri Hospital, Jawahar Nagar, Delhi"
                                gradientClass="from-blue-400 to-blue-600"
                                action={scrollToMap}
                            />
                            <ContactItem
                                icon={<FaPhone className="text-green-500" />}
                                title="Phone"
                                content="+91 9015550000"
                                gradientClass="from-green-400 to-green-600"
                                action={() => window.location.href = 'tel:+919015550000'}
                            />
                            <ContactItem
                                icon={<FaEnvelope className="text-purple-500" />}
                                title="Email"
                                content="dr.swatikanodia@gmail.com"
                                gradientClass="from-purple-400 to-purple-600"
                                action={() => window.location.href = 'mailto:dr.swatikanodia@gmail.com'}
                            />
                        </motion.div>
                    </motion.div>
                </section>

                <section className="services py-20 bg-gray-100">
                    <motion.div className="container mx-auto px-4">
                        <h2 className="text-3xl md:text-4xl lg:text-6xl font-pt-serif-regular text-center text-purple-600 mb-12">Our Services</h2>
                        <motion.div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                            {[
                                "Growth Disorders",
                                "Thyroid Disorders",
                                "Diabetes Management",
                                "Puberty Issues",
                                "Obesity Management",
                                "Adrenal Disorders",
                                "Calcium & Bone Health",
                                "Genetic Disorders",
                                "Adolescent Endocrinology"
                            ].map((service, index) => (
                                <motion.div
                                    key={index}
                                    className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow duration-300"
                                >
                                    <FaStethoscope className="text-purple-500 text-3xl mb-4" />
                                    <h3 className="text-xl font-semibold mb-2">{service}</h3>
                                </motion.div>
                            ))}
                        </motion.div>
                    </motion.div>
                </section>

                <section id="contact-form" className="contact-form bg-white py-20">
                    <motion.div className="container mx-auto px-4">
                        <motion.div className="max-w-6xl mx-auto">
                            <motion.div className="flex flex-col lg:flex-row items-center">
                                <motion.div className="w-full lg:w-1/2 lg:pl-8 mb-8 lg:mb-0 order-1 lg:order-2">
                                    <img
                                        src="/images/contact/contact-consultant.jpg"
                                        alt="Pediatric endocrinology consultation"
                                        className="rounded-lg shadow-lg w-full h-auto object-cover"
                                    />
                                </motion.div>
                                <motion.div className="w-full lg:w-1/2 lg:pr-8 order-2 lg:order-1">
                                    {state.succeeded ? (
                                        <h3 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6 lg:mb-8 text-center lg:text-left mt-4 sm:mt-6 lg:mt-0 px-4 lg:px-0">
                                            Thank you! Your appointment request has been sent successfully.
                                        </h3>
                                    ) : (
                                        <motion.div>
                                            <h3 className="text-2xl md:text-4xl text-purple-600 font-pt-serif-regular mb-8 text-center lg:text-left mt-8 lg:mt-0">Schedule Your Appointment</h3>
                                            <form onSubmit={handleSubmit} className="space-y-6">

                                                <motion.div className='mb-8'>
                                                    <label className="block text-md font-medium text-oxfored-blue mb-2">Appointment Type</label>
                                                    <motion.div className="flex space-x-4">
                                                        <label className="inline-flex items-center">
                                                            <input
                                                                type="radio"
                                                                name="appointmentType"
                                                                value="offline"
                                                                className="form-radio text-black focus:ring-zinc-700 h-4 w-4"
                                                            />
                                                            <span className="ml-2 text-oxford-blue">Offline Appointment</span>
                                                        </label>
                                                        <label className="inline-flex items-center">
                                                            <input
                                                                type="radio"
                                                                name="appointmentType"
                                                                value="online"
                                                                className="form-radio text-black focus:ring-zinc-700 h-4 w-4"
                                                            />
                                                            <span className="ml-2 text-oxford-blue">Online Appointment</span>
                                                        </label>
                                                    </motion.div>
                                                </motion.div>
                                                <motion.div className="mb-8">
                                                    <label htmlFor="name" className="block text-oxford-blue text-sm font-semibold mb-2">Child's Name</label>
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        id="name"
                                                        required
                                                        placeholder="Enter child's name"
                                                        className="border-b border-platinum py-2 text-sm w-full"
                                                    />
                                                </motion.div>
                                                <motion.div className="mb-8">
                                                    <label htmlFor="name" className="block text-oxford-blue text-sm font-semibold mb-2">Child's Age</label>
                                                    <input
                                                        type="text"
                                                        name="age"
                                                        id="age"
                                                        required
                                                        placeholder="Enter child's age"
                                                        className="border-b border-platinum py-2 text-sm w-full"
                                                    />
                                                </motion.div>
                                                <motion.div className="mb-8">
                                                    <label htmlFor="parent" className="block text-oxford-blue text-sm font-semibold mb-2">Parent's Name</label>
                                                    <input
                                                        type="text"
                                                        name="parent name"
                                                        id="parent name"
                                                        required
                                                        placeholder="Enter Parent's name"
                                                        className="border-b border-platinum py-2 text-sm w-full"
                                                    />
                                                </motion.div>
                                                <motion.div className="mb-8">
                                                    <label htmlFor="phone" className="block text-oxford-blue text-sm font-semibold mb-2">Phone Number</label>
                                                    <input
                                                        type="tel"
                                                        name="phone"
                                                        id="phone"
                                                        required
                                                        placeholder="Enter Parent's name"
                                                        className="border-b border-platinum py-2 text-sm w-full"
                                                    />
                                                </motion.div>
                                                <motion.div className="mb-8">
                                                    <label htmlFor="email" className="block text-oxford-blue text-sm font-semibold mb-2">Email</label>
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        id="email"
                                                        required
                                                        placeholder="Enter Parent's name"
                                                        className="border-b border-platinum py-2 text-sm w-full"
                                                    />
                                                </motion.div>
                                                <ValidationError prefix="Email" field="email" errors={state.errors} />



                                                <motion.div className="mb-8">
                                                    <label htmlFor="date" className="block text-oxford-blue text-sm font-semibold mb-2">Date</label>
                                                    <input
                                                        type="date"
                                                        name="date"
                                                        id="date"
                                                        required
                                                        placeholder="Select Date"
                                                        className="border-b border-platinum py-2 text-sm w-full"
                                                    />
                                                    <ValidationError prefix="Date" field="date" errors={state.errors} />
                                                </motion.div>

                                                <motion.div className="mb-8">
                                                    <label htmlFor="location" className="block text-oxford-blue text-sm font-semibold mb-2">Location</label>
                                                    <select
                                                        name="location"
                                                        id="location"
                                                        required
                                                        className="border-b border-platinum py-2 text-sm w-full"
                                                    >
                                                        <option value="">Select Location</option>
                                                        <option value="bl-kapoor-hospital">BL Kapoor Hospital, Pusa road - Monday, Tuesday, Friday & Saturday - 2-4pm</option>
                                                        <option value="rainbow-children-hospital">Rainbow Children's Hospital, Malviya nagar - Wednesday & Thursday 3-5pm</option>
                                                        <option value="childrens-multispeciality-clinic">Children's Multispeciality Clinic, Vikaspuri - Saturday 10am-12pm</option>
                                                        <option value="gauri-hospital">Gauri Hospital, Jawahar nagar - Tuesday & Friday 11am-12pm</option>
                                                        <option value="aartas-clinishare">Aartas Clinishare, Lajpat Nagar - Wednesday & Friday 6-7pm</option>
                                                        <option value="apollo-cradle">Apollo Cradle, Moti Nagar - Monday and Thursday 10am-12pm</option>
                                                    </select>
                                                </motion.div>


                                                <motion.div className="mb-8">
                                                    <label htmlFor="message" className="block text-oxford-blue text-sm font-semibold mb-2">How can I help? </label>
                                                    <textarea
                                                        name="message"
                                                        id="message"
                                                        placeholder="Type Description"
                                                        required
                                                        className="border-b border-platinum py-2 text-sm w-full max-h-72 min-h-24 h-24 resize-vertical"
                                                    ></textarea>
                                                </motion.div>
                                                <button
                                                    type="submit"
                                                    className="w-full bg-purple-900 text-white font-semibold py-3 px-6 rounded-md hover:bg-indigo-700 transition duration-300 ease-in-out transform hover:scale-105"
                                                >
                                                    Schedule Appointment
                                                </button>
                                            </form>
                                        </motion.div>)}
                                </motion.div>


                            </motion.div>
                        </motion.div>
                    </motion.div>
                </section>



            </main>
        </>

    )
}


interface ContactCardProps {
    icon: React.ReactNode;
    title: string;
    content: string;
    gradientClass: string;
    action: () => void;
}

const ContactItem: React.FC<ContactCardProps> = ({ icon, title, content, gradientClass, action }) => (
    <motion.div
        className={`rounded-lg shadow-lg p-8 text-center hover:shadow-xl transition duration-300 bg-gradient-to-br ${gradientClass} relative overflow-hidden group cursor-pointer`}
        onClick={action}
    >
        <motion.div className="absolute inset-0 bg-white opacity-90 group-hover:opacity-95 transition-opacity duration-300"></motion.div>
        <motion.div className="relative z-10 flex flex-col items-center justify-center h-full">
            <motion.div className="text-5xl mb-6 transform group-hover:scale-110 transition-transform duration-300">
                {icon}
            </motion.div>
            <h3 className="text-2xl font-bold mb-3 text-gray-800">{title}</h3>
            <p className="text-gray-600 text-lg leading-relaxed max-w-xs mx-auto">{content}</p>
        </motion.div>
    </motion.div>

);

export default Contact;