import { useState } from 'react';

const FAQItem = ({ question, children }:{question:string, children: string}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
   
    <div className="border-b border-gray-200 py-4 w-full max-w-2xl">
      <button
        className="flex justify-between items-center w-full text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="font-medium text-gray-800">{question}</span>
        <span className="ml-6 flex-shrink-0">
          {isOpen ? (
            <svg className="h-6 w-6 text-zinc-800 border rounded-[30px] border-solid border-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
            </svg>
          ) : (
            <svg className="h-6 w-6 text-zinc-800 border rounded-[30px] border-solid border-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
          )}
        </span>
      </button>
      {isOpen && (
        <div className="mt-2 text-zinc-800">
          {children}
        </div>
      )}
      
    </div>
  );
};

   
 
export default FAQItem;